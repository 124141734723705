html,body,#root{
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ce-block__content, 
.ce-toolbar__content {
 max-width: 1000px; 
 margin:10px
}


/* Remove margin for large screens */
@media (min-width: 1024px) {
  .ce-block__content,
  .ce-toolbar__content {
    padding: 0;
  }
}